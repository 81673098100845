import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Seite nicht gefunden" />
    <h1>Nicht gefunden</h1>
    <p>Diese Seite gibt es nicht. Probieren Sie die <Link to='/'>Startseite </Link></p>
  </Layout>
)

export default NotFoundPage
